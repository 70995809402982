<template lang="pug">
.ep__registration-step.ep__registration-step--framed
  el-form.vaccine-covid19-register-userinfo(
    v-loading="loading"
    label-position="top"
    ref="form"
    v-if="page === 'USER_INFO'"
    :model="form"
    :rules="rules")
    .ep__registration-header
      i
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M12 2A10.13 10.13 0 0 0 2 12a10 10 0 0 0 4 7.92V20h.1a9.7 9.7 0 0 0 11.8 0h.1v-.08A10 10 0 0 0 22 12 10.13 10.13 0 0 0 12 2zM8.07 18.93A3 3 0 0 1 11 16.57h2a3 3 0 0 1 2.93 2.36 7.75 7.75 0 0 1-7.86 0zm9.54-1.29A5 5 0 0 0 13 14.57h-2a5 5 0 0 0-4.61 3.07A8 8 0 0 1 4 12a8.1 8.1 0 0 1 8-8 8.1 8.1 0 0 1 8 8 8 8 0 0 1-2.39 5.64z"></path><path d="M12 6a3.91 3.91 0 0 0-4 4 3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4zm0 6a1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2 1.91 1.91 0 0 1-2 2z"></path></svg>
      h1 Thông tin người tiêm
      p(v-if="false") Dành cho người cao tuổi (trên 65 tuổi), người trên 18 tuổi có bệnh lý nền và phụ nữ mang thai

    section
      el-row(:gutter="20")
        el-col(:sm="12")
          el-form-item(label="Số di động người tiêm/người giám hộ" prop="phone")
            el-input(v-model="form.phone" ref="focus")

        el-col(:sm="12")
          el-form-item(label="Mã số khách hàng tại Tâm Anh (nếu có)" prop="pid")
            el-input(v-model="form.pid")

      el-row(:gutter="20")
        el-col(:sm="12")
          el-form-item(label="Họ tên người tiêm" prop="name")
            el-input.ep-uppercase(v-model="form.name")

        el-col(:sm="12")
          el-form-item(label="Ngày sinh người tiêm" prop="birthday")
            el-input(
                v-model="form.birthday"
                @change="onBirthday"
                type="date")

        el-col(:sm="12", v-if="false")
          el-form-item(label="Ngày sinh người tiêm" prop="birthday")
            el-date-picker(
                v-model="form.birthday"
                @change="onBirthday"
                popper-class="picker-date-custom-classed"
                :picker-options="birthdayOptions")
      el-button.button-icon-next(
        @click="lookup"
        v-if="is_searching"
        type="primary") TIẾP TỤC
          i
            <svg width="14" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.293 12.293L6.707 13.707L13.414 7L6.707 0.292999L5.293 1.707L9.586 6H0V8H9.586L5.293 12.293Z" fill="white"/>
            </svg>          
    section(v-if="!is_searching")            
      el-row(:gutter="20")
        el-col(:sm="12")
          el-form-item(label="Giới tính" prop="gender")
            single-choice(
              :value.sync="form.gender"
              :options="genders")
              template(v-slot:label="{ item }") {{ item }}
        el-col(:sm="12")
          el-form-item(label="Quốc tịch" prop="country_code")
            el-select(v-model="form.country_code")
              template
                el-option(
                    v-for="i, k in countrys"
                    :key="k"
                    :value="i.MAQG"
                    :label="i.TENQG") {{ i.TENQG }}

      .heading-page THÔNG TIN NGƯỜI LIÊN HỆ
      section   
        el-row(:gutter="20")
          el-col(:sm="12")
            el-form-item(label="Họ tên người liên hệ" prop="name_contact")
              el-input.ep-uppercase(v-model="form.name_contact")
          el-col(:sm="12")
            el-form-item(label="Mối quan hệ với người tiêm" prop="relation_contact")
              el-select(v-model="form.relation_contact")
                template
                  el-option(
                      v-for="i, k in relations"
                      :key="k"
                      :value="i"
                      :label="i") {{ i }}
        el-row(:gutter="20")  
          el-col(:sm="12")
            el-form-item(label="Số di động người liên hệ" prop="phone_contact")
              el-input(v-model="form.phone_contact")
      .heading-page ĐỊA CHỈ CỤ THỂ ĐANG SINH SỐNG
      section
        el-row(:gutter="20")
          el-col(:sm="12")
            el-form-item(label="Tỉnh thành" prop="province_id")
              el-select(
                v-model="form.province_id" 
                :loading="provinces === null")
                template(v-if="provinces")
                  el-option(
                    v-for="i, k in provinces"
                    :key="k"
                    :value="i.id"
                    :label="i.name") {{ i.name }}

          el-col(:sm="12")
            el-form-item(label="Quận huyện" prop="district_id")
              el-select(
                v-model="form.district_id"
                :disabled="!form.province_id"
                :placeholder="form.province_id ? 'Chọn' : 'Chọn tỉnh thành trước'")
                template(v-if="districts")
                  el-option(
                    v-for="i, k in districts"
                    :key="k"
                    :value="i.id"
                    :label="i.name") {{ i.name }}
        el-row(:gutter="20")
          el-col(:sm="12")
            el-form-item(label="Phường xã" prop="war_id")
              el-select(
                v-model="form.war_id"
                :disabled="!form.district_id"
                :placeholder="form.district_id ? 'Chọn' : 'Chọn quận huyện trước'")
                template(v-if="wards")
                  el-option(
                    v-for="i, k in wards"
                    :key="k"
                    :value="i.id"
                    :label="i.name") {{ i.name }}
          el-col(:sm="12")         
            el-form-item(label="Số nhà, tên đường" prop="address")
              el-input.ep-uppercase(v-model="form.address")

      .heading-page(v-if="isShowCMND") CMND/CCCD/PASSPORT NGƯỜI TIÊM
      section(v-if="isShowCMND")
        el-row(:gutter="20")
          el-col(:sm="12")
            el-form-item(label="Số CMND/CCCD/Passport" prop="cmnd")
              el-input.ep-uppercase(v-model="form.cmnd", placeholder="Nhập số")

          el-col(:sm="12")
            el-form-item(label="Ngày cấp" prop="cmnd_date")
              el-date-picker(
                  v-model="form.cmnd_date"
                  popper-class="picker-date-custom-classed"
                  :picker-options="birthdayOptions")
        el-row(:gutter="20")
          el-col(:sm="12")
            el-form-item(label="Nơi cấp" prop="cmnd_location")
              el-input.ep-uppercase(v-model="form.cmnd_location" placeholder="Nhập nơi cấp")

      .heading-page BẠN ĐÃ TIÊM VẮC XIN COVID-19 CHƯA?
      section
        el-form-item(label="" prop="inject_num")
            single-choice(
              :value.sync="form.inject_num"
              :options="injects")
              template(v-slot:label="{ item }") {{ item }}
        el-row(:gutter="20", v-if="form.inject_num && form.inject_num.includes('Đã tiêm')")
          el-col(:sm="3")
            el-form-item.inject-num-label(label="MŨI 1")
          el-col(:sm="7")
            el-form-item(label="" prop="vaccine_name_1")
              el-select(
                v-model="form.vaccine_name_1"
                :disabled="mui1_disable"
                placeholder="Chọn loại vắc xin")
                template
                  el-option(
                    v-for="i, k in vaccines"
                    :key="k"
                    :value="i"
                    :label="i") {{ i }}
          el-col(:sm="7")
            el-form-item(prop="vaccine_date_1")
              el-date-picker(
                  :disabled="mui1_disable"
                  v-model="form.vaccine_date_1"
                  popper-class="picker-date-custom-classed"
                  placeholder="Chọn ngày tiêm"
                  :picker-options="birthdayOptions")
          el-col(:sm="7")
            el-form-item(label="" prop="vaccine_location_1")
              el-input.ep-uppercase(
                :disabled="mui1_disable"
                v-model="form.vaccine_location_1"
                placeholder="Điền nơi tiêm")
        el-row(:gutter="20", v-if="form.inject_num == 'Đã tiêm mũi 2'")
          el-col(:sm="3")
            el-form-item.inject-num-label(label="MŨI 2")
          el-col(:sm="7")
            el-form-item(label="" prop="vaccine_name_2")
              el-select(
                :disabled="mui2_disable"
                v-model="form.vaccine_name_2"
                placeholder="Chọn loại vắc xin")
                template
                  el-option(
                    v-for="i, k in vaccines"
                    :key="k"
                    :value="i"
                    :label="i") {{ i }}
          el-col(:sm="7")
            el-form-item(prop="vaccine_date_2")
              el-date-picker(
                :disabled="mui2_disable"
                v-model="form.vaccine_date_2"
                popper-class="picker-date-custom-classed"
                placeholder="Chọn ngày tiêm"
                :picker-options="birthdayOptions")
          el-col(:sm="7")
            el-form-item(label="" prop="vaccine_location_2")
              el-input.ep-uppercase(
                :disabled="mui2_disable"
                v-model="form.vaccine_location_2"
                placeholder="Điền nơi tiêm")
        .vaccine-inject-note(v-if="form.inject_num && form.inject_num.includes('Đã tiêm')") Quý khách vui lòng mang theo Giấy xác nhận đã tiêm vắc xin COVID-19 khi đến tiêm vắc xin tại Bệnh viện                                       
      .heading-page CHỌN ĐỊA ĐIỂM MONG MUỐN TIÊM
      section
        el-form-item(prop="location")
          single-choice(
            :value.sync="form.location"
            :options="locations")
            template(v-slot:label="{ item }")
              b {{ item.name }} 
              br
              span(style="padding-left: 25px") {{ item.address }} 
      el-button.button-icon-next(
        @click="nextPage()"
        type="primary") TIẾP TỤC
          i
            <svg width="14" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.293 12.293L6.707 13.707L13.414 7L6.707 0.292999L5.293 1.707L9.586 6H0V8H9.586L5.293 12.293Z" fill="white"/>
            </svg>
  el-form.vaccine-covid19-register-userinfo(
    v-loading="loading"
    label-position="top"
    ref="form"
    v-if="page === 'MEDICAL_INPUT'"
    :model="form"
    :rules="rules")
    .ep__registration-header
      h1 Thông tin y khoa & bệnh lý
      p(v-if="false") Dành cho người cao tuổi (trên 65 tuổi), người trên 18 tuổi có bệnh lý nền và phụ nữ mang thai

    section
      el-row(:gutter="20")
        el-col
          el-form-item(label="Vui lòng chọn đối tượng phù hợp với tình trạng người được tiêm" prop="injected_subject")
            single-choice.ep-grid(
                :value.sync="form.injected_subject"
                @change="changeInjectSubject"
                :options="getInjectSubject()")
                template(v-slot:label="{ item }") {{ item.name }}
      el-row(:gutter="20")
        el-col(:sm="24")
          el-form-item(label="Quý khách vui lòng ghi rõ tình trạng sức khoẻ của mình" prop="benhnen")
            single-choice(
                :value.sync="form.benhnen"
                @change="changeHealthCondition"
                :options="health_conditions")
                template(v-slot:label="{ item }") {{ item }}
      el-row(:gutter="20", v-if="form.benhnen == 'Có bệnh lý nền'")
        el-col(:sm="24")
          el-form-item(label="Nhập các loại bệnh lý gặp phải" prop="dsbenhnen")
            el-input(type="textarea" rows="3" v-model="form.dsbenhnen") 
      el-row(:gutter="20")
        el-col(:sm="24")
          el-form-item(label="Người tiêm đã từng là F0?" prop="is_f0")
            single-choice(
                :value.sync="form.is_f0"
                :options="boolean_list")
                template(v-slot:label="{ item }") {{ item }}
      el-row(:gutter="20", v-if="form.is_f0 == 'Có'")
          el-col(:sm="8")
            el-form-item.inject-num-label(label="Ngày hoàn tất điều trị F0")
          el-col(:sm="10")
            el-form-item(prop="f0_date")
              el-date-picker(
                  v-model="form.f0_date"
                  popper-class="picker-date-custom-classed"
                  placeholder="Chọn ngày hoàn tất điều trị F0"
                  :picker-options="birthdayOptions")            
      el-row(:gutter="20", v-if='false')
        el-col(:sm="24")
          el-form-item(label="14 ngày gần đây, Quý khách có sử dụng thuốc kháng sinh hoặc tiêm bất kỳ loại vắc xin nào không?" prop="dungthuoc_khangsinh_14ngay")
            single-choice(
                :value.sync="form.dungthuoc_khangsinh_14ngay"
                :options="boolean_list")
                template(v-slot:label="{ item }") {{ item }}  
      el-row(:gutter="20", v-if='false')
        el-col(:sm="24")
          el-form-item(label="Quý khách có mong muốn đăng ký khám chuyên khoa khác cùng ngày hay không?" prop="dkkham_khoakhac")
            single-choice(
                :value.sync="form.dkkham_khoakhac"
                :options="boolean_list")
                template(v-slot:label="{ item }") {{ item }}  
      el-row(:gutter="20", v-if="false && form.injected_subject && form.injected_subject.id === 'PREGNANT_WOMEN'")
        el-col(:sm="24")
          el-form-item(label="Người được tiêm có đang mang thai hay không?" prop="is_pregnant")
            single-choice(
                :value.sync="form.is_pregnant"
                :options="boolean_list")
                template(v-slot:label="{ item }") {{ item }}
      el-row(:gutter="20", v-if="form.injected_subject && form.injected_subject.id === 'PREGNANT_WOMEN'")
        el-col(:sm="24")
          el-form-item(label="Vui lòng nhập tuần thai người được tiêm" prop="pregnant_week")
            el-input(
                v-model="form.pregnant_week"
                placeholder="Nhập số tuần thai") 
      el-row(:gutter="20", v-if="form.injected_subject && form.injected_subject.id === 'PREGNANT_WOMEN'")
        el-col(:sm="24")
          el-form-item(label="Người được tiêm có từng sinh con ở BV Tâm Anh hay không?" prop="is_birth_ta")
            single-choice(
                :value.sync="form.is_birth_ta"
                :options="boolean_list")
                template(v-slot:label="{ item }") {{ item }}
      el-row(:gutter="20", v-if="form.injected_subject && form.injected_subject.id === 'PREGNANT_WOMEN' && form.is_birth_ta == 'Có'")
          el-col(:sm="8")
            el-form-item.inject-num-label(label="Ngày sinh")
          el-col(:sm="10")
            el-form-item(prop="born_date")
              el-date-picker(
                  v-model="form.born_date"
                  popper-class="picker-date-custom-classed"
                  placeholder="Chọn ngày sinh"
                  :picker-options="birthdayOptions")         
      el-row
        el-button.back-button-userinfo(@click="page = 'USER_INFO'") QUAY LẠI                                    
        el-button.button-icon-next(
          @click="onSummary"
          type="primary") TIẾP TỤC
            i
              <svg width="14" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.293 12.293L6.707 13.707L13.414 7L6.707 0.292999L5.293 1.707L9.586 6H0V8H9.586L5.293 12.293Z" fill="white"/>
              </svg>  
  el-form.vaccine-covid19-register-success(
    v-loading="loading"
    label-position="top"
    ref="form"
    v-if="page === 'REGISTER_SUCCESS'"
    :model="form"
    :rules="rules")
    .ep__registration-header
      h1 Hoàn thành đăng ký
    section
      div
        p Cảm ơn Quý khách hàng đã đăng ký thông tin. Hệ thống sẽ sắp xếp lịch tiêm và nhắn tin đặt hẹn đến Quý khách qua&nbsp;
          a(style="font-weight: bold;", href="https://oa.zalo.me/4356537378985331656") Kênh Zalo chính thức của BVĐK Tâm Anh 
          span &nbsp;hoặc tin nhắn.
      p Người được mời tiêm phải có xác nhận đặt hẹn hợp lệ từ BVĐK Tâm Anh để làm căn cứ được phục vụ tiêm chủng và có mặt tại Bệnh viện theo giờ được hẹn. Người đến tiêm chủng cần trình diện CMND/CCCD/Passport có thông tin trùng với thông tin đã đăng ký và xác nhận đặt hẹn.
      el-col
        .ep-section__description.inject_info_title Thông tin người tiêm
      el-row(:gutter="20")
        el-col(:sm="12")  
          table.eplus-card
            div.row-vaccine-info
              div.eplus-card__icon
                img(src="../assets/user.svg")
              div.eplus-card__preview 
                p.eplus-strong {{ form.name.toUpperCase() }}
            div.row-vaccine-info
              div.eplus-card__icon
                img(src="../assets/pid.svg")
              div.eplus-card__preview 
                p.eplus-strong {{ getPidBirthday() }}
            div.row-vaccine-info
              div.eplus-card__icon
                img(src="../assets/phone.svg")
              div.eplus-card__preview 
                p.eplus-strong {{ form.phone }} 
            div.row-vaccine-info
              div.eplus-card__icon
                img(src="../assets/map.svg")
              div.eplus-card__preview 
                p.eplus-strong {{ getAddressFull() }}     
            div.row-vaccine-info(style="border: none; align-items: flex-start;")
              div.eplus-card__icon(style="margin-top: 7px;")
                img(src="../assets/cmnd.svg")
              div.eplus-card__preview(v-if="form.cmnd || form.cmnd_date || form.cmnd_location")
                p.eplus CMND: {{ form.cmnd ? form.cmnd : '' }}  
                p.eplus Ngày cấp: {{ form.cmnd_date ? getDateStr(form.cmnd_date) : '' }}  
                p.eplus Nơi cấp: {{ form.cmnd_location ? form.cmnd_location.toUpperCase() : '' }}  
        el-col(:sm="12")
          .eplus-card.booking-detail-summary(style="background-color: #ECF0FA; padding-bottom: 10px;")
            div.booking-detail-line
              div.row-booking-left
                span Đối tượng
              div.row-booking-right
                span.eplus-strong {{ form.injected_subject.name }}
            div.booking-detail-line
              div.row-booking-left
                span Bệnh lý
              div.row-booking-right
                span.eplus-strong {{ form.benhnen }}    
            div.booking-detail-line
              div.row-booking-left
                span Tình trạng tiêm
              div.row-booking-right
                span.eplus-strong {{ form.inject_num }}
            div.booking-detail-line(v-for="i, k in getListVaccine()" :key="k")
              div.row-booking-left
                span Mũi {{ i.order }}
              div.row-booking-right
                div.eplus-strong {{ getDateStr(i.date) }}
                div.eplus-strong {{ getEnumVaccine(i.name) }}
                div.eplus-strong {{ i.location.toUpperCase() }}
      p Để đảm bảo khách quan, trung thực và công bằng, Bệnh viện Tâm Anh có thể từ chối tiêm chủng với Khách hàng đăng ký thông tin không chính xác và không có đủ các điều kiện kể trên. Vì hệ thống sẽ tự động loại các thông tin trùng khớp nên mỗi khách hàng chỉ cần đăng ký một lần duy nhất.
      p Chi tiết Quy định về việc tiêm vắc xin phòng Covid-19 tại Bệnh viện Đa khoa Tâm Anh Tp. HCM, vui lòng đọc&nbsp;
        a(href="https://tamanhhospital.vn/tam-anh-tiem-vac-xin-ngua-covid-19-cho-nguoi-cao-tuoi-benh-nen/") tại đây.     
      p Trong trường hợp cần hỗ trợ gấp hoặc cần giải đáp thắc mắc, Quý khách vui lòng liên hệ:
      b HỆ THỐNG BỆNH VIỆN ĐA KHOA TÂM ANH
      br
      ul
        li
          b Bệnh viện Đa khoa Tâm Anh TP. HCM
          p 2B Phổ Quang, Phường 2, Tân Bình, TP. HCM 
          p 
            a.phone(href="tel:02871026789") 0287 102 6789
          p  
            a.mail(href="mailto:info@hcm.tahospital.vn") info@hcm.tahospital.vn
        li  
          b Bệnh viện Đa khoa Tâm Anh (Hà Nội)
          p 108 Hoàng Như Tiếp, Bồ Đề, Long Biên, Hà Nội
          p
            a.phone(href="tel:18006858") 1800 6858
          p  
            a.mail(href="mailto:info@tamanhhospital.vn") info@tamanhhospital.vn          

</template>

<script>
import moment from 'moment'
import * as $v from '../services/validators'
import * as $n from '../services/normalizers'
import Base from './Base'
import taLogo from '../assets/ta_logo.png'
import { MessageBox } from 'element-ui'
import { find } from 'lodash'

export default {
  extends: Base,

  data () {
    return {
      key: 'VaccineCovid19Register',
      taLogo,
      vaccines: [
        'AZ (Anh)',
        'MO (Mỹ-Đức)',
        'PZ (Mỹ)',
        'VE (Trung Quốc)'
      ],
      provinces: [],
      districts: [],
      wards: [],
      page: 'USER_INFO',
      is_searching: true,
      rules: {
        phone: [$v.required, ...$v.phone],
        name: [$v.required, ...$v.fullName],
        birthday: [$v.required],
        gender: [$v.required],
        country_code: [$v.required],
        province_id: $v.required,
        district_id: $v.required,
        war_id: $v.required,
        address: [$v.required, ...$v.street],
        cmnd: [$v.required],
        cmnd_date: [$v.required],
        cmnd_location: [$v.required],
        injected_subject: [$v.required],
        health_condition: [$v.required],
        dsbenhnen: [$v.required],
        benhnen: [$v.required],
        dungthuoc_khangsinh_14ngay: [$v.required],
        dkkham_khoakhac: [$v.required],
        location: [$v.required],
        is_pregnant: [$v.required],
        is_birth_ta: [$v.required],
        pregnant_week: [$v.required, ...$v.quantity],
        inject_num: [$v.required],
        vaccine_name_1: [$v.required],
        vaccine_date_1: [$v.required],
        vaccine_location_1: [$v.required],
        vaccine_name_2: [$v.required],
        vaccine_date_2: [$v.required],
        vaccine_location_2: [$v.required],
        born_date: [$v.required],
        is_f0: [$v.required],
        f0_date: [$v.required],
        pid: [...$v.number],
        name_contact: [$v.required, ...$v.fullName],
        phone_contact: [$v.required, ...$v.phone],
        relation_contact: [$v.required]
      },
      genders: ['Nam', 'Nữ'],
      countrys: [],
      injects: [],
      injected_subjects: [
        {
          id: 'ELDERLY',
          name: 'Người cao tuổi (trên 65 tuổi)'
        },
        {
          id: 'PREGNANT_WOMEN',
          name: 'Khách hàng khám sản (đang mang thai)'
        },
        {
          id: 'NON_PREGNANT_WOMEN',
          name: 'Khách hàng khám sản (không mang thai)'
        },
        {
          id: 'FERTILITY_CENTER',
          name: 'Khách hàng thăm khám tại Trung tâm Hỗ trợ sinh sản'
        },
        {
          id: 'OTHER',
          name: 'Đối tượng khác'
        },
        {
          id: 'CHILDREN',
          name: 'Trẻ em từ 12 đến dưới 18 tuổi'
        }
      ],
      injected_subjectshn: [
        {
          id: 'PREGNANT_WOMEN',
          name: 'Khách hàng khám sản (đang mang thai)'
        },
        {
          id: 'NON_PREGNANT_WOMEN',
          name: 'Khách hàng khám sản (không mang thai)'
        },
        {
          id: 'FERTILITY_CENTER',
          name: 'Khách hàng thăm khám tại Trung tâm Hỗ trợ sinh sản'
        }
      ],
      health_conditions: ['Không có bệnh lý nền', 'Có bệnh lý nền'],
      boolean_list: ['Có', 'Không'],
      underlyings: [
        'Đái tháo đường',
        'Bệnh phổi tắc nghẽn mạn tính và các bệnh phổi khác',
        'Ung thư (đặc biệt là các khối u ác tính về huyết học, ung thư phổi và bệnh ung thư di căn khác)',
        'Bệnh thận mạn tính',
        'Béo phì, thừa cân',
        'Bệnh tim mạch (suy tim, bệnh động mạch vành hoặc bệnh cơ tim)',
        'Bệnh lý mạch máu não',
        'Hội chứng Down',
        'HIV/AIDS',
        'Bệnh lý thần kinh (bao gồm cả chứng sa sút trí tuệ)',
        'Bệnh hồng cầu hình liềm, bệnh thalassemia, bệnh huyết học mạn tính khác',
        'Hen phế quản',
        'Tăng huyết áp',
        'Thiếu hụt miễn dịch',
        'Bệnh gan',
        'Rối loạn do sử dụng chất gây nghiện',
        'Đang điều trị bằng thuốc corticosteroid hoặc các thuốc ức chế miễn dịch khác',
        'Các bệnh hệ thống',
        'Bệnh lý mắc lúc nhỏ như: Tăng áp phổi nguyên hoặc thứ phát, bệnh tim bẩm sinh, rối loạn chuyển hóa di truyền bẩm sinh, rối loạn nội tiết bẩm sinh-mắc phải.',
        'Bệnh lý khác'
      ],
      locations: [
        {
          id: 'BV-TAMANH-HCM',
          name: 'Bệnh viện Đa khoa Tâm Anh TP. HCM',
          address: '2B Phổ Quang, Phường 2, Tân Bình, TP. HCM' 
        },
        {
          id: 'BV-TAMANH-HN',
          name: 'Bệnh viện Đa khoa Tâm Anh Hà Nội',
          address: '108 Hoàng Như Tiếp, Bồ Đề, Long Biên, Hà Nội ' 
        }
      ],
      relations: [
        'Bản thân', 'Con', 'Cha', 'Mẹ', 'Vợ', 'Chồng', 'Anh', 'Chị', 'Em', 'Ông', 'Bà', 'Họ hàng', 'Khác'
      ],
      mui1_disable: false,
      mui2_disable: false,
      isShowCMND: true
    }
  },

  computed: {
    birthdayOptions () {
      return {
        disabledDate: d => d.valueOf() > Date.now()
      }
    }
  },

  watch: {
    'form.province_id': {
      handler (provinceId) {
        console.log('sdfsdfdsf', provinceId)
        this.districts = null
        this.$set(this.form, 'district_id', null)
        this.$apis.master
          .getDistrictsList({ provinceId })
          .then(res => {
            this.districts = res
          })
      }
    },
    'form.district_id': {
      handler (districtId) {
        this.wards = null
        this.$set(this.form, 'war_id', null)
        this.$apis.master
          .getWardsList({ districtId })
          .then(res => {
            this.wards = res
          })
      }
    },
  },

  methods: {
    reset () {
      this.finished = false
      this.loading = false
      this.submitting = false
      this.isLoading = false
      this.isShowCMND = true
      this.form = {
        phone: null,
        name: null,
        birthday: null,
        gender: null,
        country: null,
        country_code: null,
        phone_contact: null,
        name_contact: null,
        relation_contact: null,
        province_id: null,
        district_id: null,
        war_id: null,
        address: null,
        cmnd: null,
        cmnd_date: null,
        cmnd_location: null,
        injected_subject: null,
        health_condition: null,
        dungthuoc_khangsinh_14ngay: null,
        dkkham_khoakhac: null,
        benhnen: null,
        dsbenhnen: [],
        list_vx: [],
        is_pregnant:null,
        is_birth_ta: null,
        is_f0: null,
        f0_date: null,
        pid: null
      }
      this.fetch()
    },

    normalize (val) {
      return {
        ...val,
        name: $n.fullName(val.name),
        address: $n.street(val.address),
        cmnd: $n.fullName(val.cmnd),
        cmnd_location: $n.fullName(val.cmnd_location),
        vaccine_location_1: $n.fullName(val.vaccine_location_1),
        vaccine_location_2: $n.fullName(val.vaccine_location_2),
        name_contact: $n.fullName(val.name_contact)
      }
    },

    changeHealthCondition (benhnen) {
      const { injected_subject } = this.form
      const subjects_required = ['ELDERLY', 'OTHER', 'CHILDREN']
      if (injected_subject && injected_subject.id && subjects_required.includes(injected_subject.id) && benhnen == 'Không có bệnh lý nền') {
        MessageBox({
          title: 'Cảnh báo',
          message: `
            Hiện nay bệnh viện ưu tiên tiêm vắc xin cho đối tượng <b>có các bệnh lý nền</b>. Bạn có muốn tiếp tục?
          `,
          showCancelButton: true,
          showConfirmButton: true,
          dangerouslyUseHTMLString: true,
          callback: () => {}
        })
      }
    },

    changeInjectSubject (subject) {
      const { benhnen } = this.form
      const subjects_required = ['ELDERLY', 'OTHER', 'CHILDREN']
      if (subject && subject.id && subjects_required.includes(subject.id) && benhnen && benhnen == 'Không có bệnh lý nền') {
        MessageBox({
          title: 'Cảnh báo',
          message: `
            Hiện nay bệnh viện ưu tiên tiêm vắc xin cho đối tượng <b>có các bệnh lý nền</b>. Bạn có muốn tiếp tục?
          `,
          showCancelButton: true,
          showConfirmButton: true,
          dangerouslyUseHTMLString: true,
          callback: () => {}
        })
      }
    },

    onBirthday(birthday) {
      if (moment(birthday).isAfter(moment())) {
        this.form.birthday = null
      }
      const _now = moment()
      const _birthday = moment(birthday)
      const diff = moment.duration(_now.diff(_birthday))
      const diffYear = diff.years()
      this.isShowCMND = diffYear >= 15
      if (diffYear >= 18) {
        this.rules = {
          ...this.rules,
          cmnd: [$v.required],
          cmnd_date: [$v.required],
          cmnd_location: [$v.required]
        }
      } else {
        this.rules = {
          ...this.rules,
          cmnd: [],
          cmnd_date: [],
          cmnd_location: []
        }
      }
    },

    getPidBirthday () {
        const { birthday, gender } = this.form
        let str = this.form.pid ? `PID: ${this.form.pid} - ` : ''
        return str + `${gender} - ${this.getDateStr(birthday)}`
    },

    getAddressFull () {
      const { war_id, district_id, province_id, address } = this.form
      let _province = this.getNameByIdInList(this.provinces, province_id)
      let _district = this.getNameByIdInList(this.districts, district_id)
      let _ward = this.getNameByIdInList(this.wards, war_id)
      return `${address.toUpperCase()}, ${_ward.name}, ${_district.name}, ${_province.name}`
    },

    fetch() {
      this.loading = true
      return Promise
        .all([
          this.$apis.master
            .getProvincesList()
            .then(res => {
              this.provinces = res
            }),
          this.$apis.forms
            .getCountrys()
            .then(res => {
              this.countrys = res.data
            })  
        ])
        .finally(() => {
          this.loading = false
        })
    },

    showWarningMessage(message) {
      MessageBox({
        title: 'Thông tin chưa chính xác',
        message: message,
        showCancelButton: false,
        showConfirmButton: false,
        dangerouslyUseHTMLString: true,
        callback: () => {}
      })
    },

    onSummary() {
      const form = this.$refs.form
      if (!form) return
      form.validate(ok => {
        if (!ok) {
          for (const i in form.fields) {
            const field = form.fields[i]
            if (field.validateState === 'error') {
              const rect = field.$el.getBoundingClientRect()
              if (rect) window.scrollTo(0, rect.top + window.scrollY - 72)
              for (const j in field.$children) {
                const focuser = field.$children[j]
                if (focuser.focus) {
                  focuser.focus()
                  break
                }
              }
              break
            }
          }
          return
        }
        const { gender, injected_subject, birthday, is_pregnant } = this.form
        const _now = moment()
        const _birthday = moment(birthday)
        const diff = moment.duration(_now.diff(_birthday))
        const yearOld = diff.years()
        console.log('yearOld', yearOld)
        if (gender == 'Nam' && injected_subject && injected_subject.id == 'PREGNANT_WOMEN') {
          this.showWarningMessage(`
            Người được tiêm có giới tính Nam, Quý khách vui lòng khai báo thông tin chính xác để được xác nhận khi đến tiêm chủng.<br><br>
            Để đảm bảo khách quan, trung thực và công bằng, Bệnh viện Tâm Anh có thể từ chối tiêm chủng với Khách hàng đăng ký thông tin không chính xác.
          `)
          return
        }
        if (injected_subject && injected_subject.id == 'PREGNANT_WOMEN' && is_pregnant == 'Không') {
          this.showWarningMessage(`
            Quý khách vui lòng khai báo thông tin chính xác để được xác nhận khi đến tiêm chủng.<br><br>
            Để đảm bảo khách quan, trung thực và công bằng, Bệnh viện Tâm Anh có thể từ chối tiêm chủng với Khách hàng đăng ký thông tin không chính xác.
          `)
          return
        }
        if (injected_subject && injected_subject.id == 'CHILDREN' && !(yearOld >= 12 && yearOld < 18)) {
          this.showWarningMessage(`
            Người được tiêm không nằm trong độ tuổi từ đủ 12 tuổi đến dưới 18 tuổi, Quý khách vui lòng khai báo thông tin chính xác để được xác nhận khi đến tiêm chủng.<br><br>
            Để đảm bảo khách quan, trung thực và công bằng, Bệnh viện Tâm Anh có thể từ chối tiêm chủng với Khách hàng đăng ký thông tin không chính xác.
          `)
          return
        }
        // if (injected_subject && injected_subject.id == 'UNDER_18' && moment().format("YYYY") - moment(birthday).format('YYYY') >= 18) {
        //   this.showWarningMessage(`
        //     Người được tiêm chưa đủ 18 tuổi để thuộc đối tượng "Người trên 18 tuổi có bệnh lý nền", Quý khách vui lòng khai báo thông tin chính xác để được xác nhận khi đến tiêm chủng.<br><br>
        //     Để đảm bảo khách quan, trung thực và công bằng, Bệnh viện Tâm Anh có thể từ chối tiêm chủng với Khách hàng đăng ký thông tin không chính xác.
        //   `)
        //   return
        // }
        // if (moment().format("YYYY") - moment(birthday).format('YYYY') < 18) {
        //   this.showWarningMessage(`
        //     Người được tiêm đang trên 18 tuổi, Quý khách vui lòng khai báo thông tin chính xác.
        //   `)
        //   return
        // }
        if (injected_subject && injected_subject.id == 'ELDERLY' && yearOld < 65) {
          this.showWarningMessage(`
            Người được tiêm chưa đủ 65 tuổi, Quý khách vui lòng khai báo thông tin chính xác để được xác nhận khi đến tiêm chủng.<br><br>
            Để đảm bảo khách quan, trung thực và công bằng, Bệnh viện Tâm Anh có thể từ chối tiêm chủng với Khách hàng đăng ký thông tin không chính xác.
          `)
          return
        }

        this.saveRequest()
      })
    },

    saveRequest () {
      const { location, province_id, district_id, war_id } = this.form
      let _province = this.getNameByIdInList(this.provinces, province_id)
      let _district = this.getNameByIdInList(this.districts, district_id)
      let _ward = this.getNameByIdInList(this.wards, war_id)
      let province = null
      let district = null
      let ward = null
      if (location.id === 'BV-TAMANH-HN') {
        province = _province.mapping.tamanh_hn
        district = _district.mapping.tamanh_hn
        ward = _ward.mapping.tamanh_hn
      } else {
        province = _province.mapping.tamanh_hcm
        district = _district.mapping.tamanh_hcm
        ward = _ward.mapping.tamanh_hcm
      }

      const _form = {
        ...this.form,
        province_id: province,
        district_id: district,
        war_id: ward,
        location_inject: location.id,
        province: _province.name,
        district: _district.name,
        ward: _ward.name,
        country: this.getCountryName()
      }

      this.loading = true
      this.$apis.forms
      .push(this.key, {
        _source: this.source,
        ...this.normalize(_form)
      })
      .then(() => {
        this.page = 'REGISTER_SUCCESS'
        this.scrollToTop()
      })
      .finally(() => {
        this.loading = false
      })
    },

    getCountryName () {
      const _countrys = this.countrys
      const { country_code } = this.form
      if (country_code) {
        for (const item of _countrys) {
          if (item.MAQG == country_code) {
            return item.TENQG
          }
        }
        return country_code
      }
      return ''
    },

    getNameByIdInList (list, id) {
      let result = find(list, (obj) => {
        if (obj.id == id) {
          return true
        }
      })
      return result
    },

    getListVaccine () {
      const { 
        vaccine_name_1, 
        vaccine_date_1, 
        vaccine_location_1,
        vaccine_name_2,
        vaccine_date_2, 
        vaccine_location_2 
      } = this.form
      let vaccines = []
      if (vaccine_name_1) {
        vaccines.push({
          name: vaccine_name_1,
          date: vaccine_date_1,
          location: vaccine_location_1,
          order: 1
        })
        if (vaccine_name_2) {
          vaccines.push({
            name: vaccine_name_2,
            date: vaccine_date_2,
            location: vaccine_location_2,
            order: 2
          })
        }
      }
      return vaccines
    },

    nextPage () {
      const form = this.$refs.form
      if (!form) return
      form.validate(ok => {
        if (!ok) {
          for (const i in form.fields) {
            const field = form.fields[i]
            if (field.validateState === 'error') {
              const rect = field.$el.getBoundingClientRect()
              if (rect) window.scrollTo(0, rect.top + window.scrollY - 72)
              for (const j in field.$children) {
                const focuser = field.$children[j]
                if (focuser.focus) {
                  focuser.focus()
                  break
                }
              }
              break
            }
          }
          return
        }
        this.page = 'MEDICAL_INPUT'
        this.scrollToTop()
      })
    },

    lookup () {
      const form = this.$refs.form
      if (!form) return
      form.validate(ok => {
        if (!ok) {
          for (const i in form.fields) {
            const field = form.fields[i]
            if (field.validateState === 'error') {
              const rect = field.$el.getBoundingClientRect()
              if (rect) window.scrollTo(0, rect.top + window.scrollY - 72)
              for (const j in field.$children) {
                const focuser = field.$children[j]
                if (focuser.focus) {
                  focuser.focus()
                  break
                }
              }
              break
            }
          }
          return
        }

        MessageBox({
          title: 'Xác nhận thông tin',
          message: `
            Bạn vui lòng kiểm tra thông tin chính xác để đảm bảo nhận được thông tin đặt hẹn<br><br>
            <p>
              Số di động: 
              <b style="font-size: 17px;">${this.form.phone}</b>
            </p>
            <p>
              Họ tên người tiêm: 
              <b style="font-size: 17px;">${this.form.name.toUpperCase()}</b>
            </p>
            <p>
              Ngày sinh người tiêm: 
              <b style="font-size: 17px;">${this.getDateStr(this.form.birthday)}</b>
            </p>
          `,
          showCancelButton: true,
          showConfirmButton: true,
          dangerouslyUseHTMLString: true,
          callback: (action) => {
            if (action == 'confirm') {
              this.loading = true
              const data = {
                phone: this.form.phone,
                birthday: moment(this.form.birthday).format('YYYY-MM-DD')
              }
              this.$apis.forms
              .getRecordKeyWeb(data)
              .then((res) => {
                if (res?.data && res?.data?.phone) {
                  console.log('tes', res.data)
                  let {
                    list_vx,
                    benhnen
                  } = res.data
                  let vaccine_name_1 = null
                  let vaccine_date_1 = null
                  let vaccine_location_1 = null
                  let vaccine_name_2 = null
                  let vaccine_date_2 = null
                  let vaccine_location_2 = null
                  let injects = ['Chưa tiêm', 'Đã tiêm mũi 1', 'Đã tiêm mũi 2']
                  list_vx.forEach((item) => {
                    if (item?.vx_order == '1') {
                      vaccine_name_1 = this.getEnumVaccine(item.vx_name)
                      vaccine_date_1 = item.vx_date
                      vaccine_location_1 = item.vx_location
                      if (item.type_author == 'system') {
                        this.mui1_disable = true
                      }
                    } else {
                      vaccine_name_2 = this.getEnumVaccine(item.vx_name)
                      vaccine_date_2 = item.vx_date
                      vaccine_location_2 = item.vx_location
                      if (item.type_author == 'system') {
                        this.mui2_disable = true
                      }
                    }
                  })
                  switch (list_vx.length) {
                    case 0:
                      this.injects = injects
                      break
                    case 1:
                      this.injects = ['Đã tiêm mũi 1', 'Đã tiêm mũi 2']
                      break 
                    case 2:
                      this.injects = ['Đã tiêm mũi 2']
                      break   
                  }
                  if (benhnen !== null && benhnen !== '') {
                    benhnen = benhnen == 'Có' ? 'Có bệnh lý nền' : 'Không có bệnh lý nền'
                  }
                  this.form = {
                    ...this.form,
                    ...res.data,
                    inject_num: injects[list_vx.length],
                    vaccine_name_1,
                    vaccine_date_1,
                    vaccine_location_1,
                    vaccine_name_2,
                    vaccine_date_2,
                    vaccine_location_2,
                    benhnen,
                    province_id: null,
                    province: null,
                    district_id: null,
                    district: null,
                    war_id: null,
                    ward: null
                  }
                }
              })
              .finally(() => {
                this.injects = ['Chưa tiêm', 'Đã tiêm mũi 1', 'Đã tiêm mũi 2']
                const _now = moment()
                const { birthday, name, phone, phone_contact } = this.form
                const _birthday = moment(birthday)
                const diff = moment.duration(_now.diff(_birthday))
                const diffYear = diff.years()
                if (diffYear >= 18) {
                  this.form = {
                    ...this.form,
                    name_contact: name,
                    phone_contact: phone_contact ? phone_contact : phone,
                    relation_contact: 'Bản thân'
                  }
                }
                this.isShowCMND = diffYear >= 15
                if (diffYear >= 18) {
                  this.rules = {
                    ...this.rules,
                    cmnd: [$v.required],
                    cmnd_date: [$v.required],
                    cmnd_location: [$v.required]
                  }
                } else {
                  this.rules = {
                    ...this.rules,
                    cmnd: [],
                    cmnd_date: [],
                    cmnd_location: []
                  }
                }
                this.loading = false
                this.is_searching = false
              })
            }
          }
        })
      })
    },

    getInjectSubject() {
      const { injected_subjects, form } = this
      const { id } = form.location
      if (id === 'BV-TAMANH-HN') {
        return this.injected_subjectshn
      }
      return injected_subjects
    },

    getEnumVaccine (fullString) {
      const lowcaseStr = fullString.toLowerCase()
      if (lowcaseStr.includes('astrazeneca') || lowcaseStr.includes('az') || lowcaseStr.includes('as')) {
        return 'AstraZeneca'
      }
      if (lowcaseStr.includes('verocell') || lowcaseStr.includes('ve')) {
        return 'VeroCell'
      }
      if (lowcaseStr.includes('moderna') || lowcaseStr.includes('mo')) {
        return 'Moderna'
      }
      if (lowcaseStr.includes('pfizer') || lowcaseStr.includes('pz') || lowcaseStr.includes('pf')) {
        return 'Pfizer'
      }
      return fullString
    },

    scrollToTop () {
      const rect = this.$el.getBoundingClientRect()
      console.log(rect.top + window.scrollY - 72)
      if (rect) window.scrollTo(0, rect.top + window.scrollY - 72)
    },

    getDateStr (date, format = 'DD/MM/YYYY') {
      return date ? moment(date).format(format) : date
    }
  }
}
</script>
<style lang="scss" scoped>
.eplus {
  &-body {
    border-collapse: separate !important;
    border-radius: 0 0 8px 8px;
    border: 1px solid #ddd;
    border-top: none;
  }
  &-contact-list {
    margin-bottom: 0;
    padding-inline-start: 28px;
  }
  &-header {
    border-radius: 8px 8px 0 0 !important;
    * {
      vertical-align: middle !important;
    }
    &__logo {
      padding: 16px !important;
      img {
        width: 64px !important;
      
      }
    }
    &__introduce {
      // padding: 24px 16px 24px 0 !important;
      padding: 12px 12px 12px 0 !important;
    }
  }
  &-title {
    font-size: 18px;
    line-height: 1.2em !important;
    font-weight: bold;
    color: white;
    margin: 0 !important;
    
  }
  &-sub-title {
    font-size: 16px;
    color: white !important;
    margin: .2em 0 0 0 !important;
    
  }
  &-hotline {
    font-weight: bold;
    margin: .25em 0 .5em 0;
    a {
      font-weight: bold;
    }
  }
  &-legend {
    font-weight: bold;
    margin: 16px 0 12px;
  }
  &-strong {
    font-weight: bold;
  }
  &-alt {
    font-size: .9em !important;
  }
  &-card {
    border: 1px solid #ddd !important;
    border-collapse: separate !important;
    border-radius: 4px !important;
    margin: 12px 0 16px !important;
    width: 100%;
    tr {
      &:not(:last-child) {
        td {
          border-bottom: 1px solid #ddd;
        }
      }
    }
    &__icon {
      text-align: center;
      img {
        width: 20px !important;
        display: inline-block;
      }
    }
    .icon-passport {
      position: absolute !important;
    }
    .booking-detail-line {
      margin-top: 10px;
      padding: 0px 15px;
      display: flex;
      .row-booking-left {
        width: 40%;
      }
      .row-booking-right {
        width: 55%;
      }
    }
    &__preview {
      padding: 10px 10px 10px 0 !important;
      p {
        margin: 0 !important;
      }
      p + p {
        margin-top: .25em !important;
      }
    }
  }
  &-summary {
    &__price {
      text-align: right;
    }
  }
  &-footer {
    background: transparent !important;
  }
  &-partners {
    text-align: center;
    a {
      display: inline-block;
      margin: 0 5px;
      vertical-align: middle;
    }
  }
  &-company {
    text-align: center;
    &__about {
      text-align: center;
      font-size: 12px !important;
    }
    &__contact {
      text-align: center;
      font-size: 12px !important;
      > a {
        margin: 0 5px;
      }
    }
  }
}
</style>

<style lang="scss">
.vaccine-covid19-register-userinfo {
  .ep__registration-header {
    p {
      font-weight: 600;
      color: #102E9E;
      font-size: 16px;
    }
  }
  .button-icon-next {
    span {
      display: flex;
      align-items: flex-start;
      i {
        margin-left: 5px;
      }
    }
  }
  .heading-page {
    font-size: 16px;
    font-weight: 600;
    color: #282828;
    margin-bottom: 15px;
  }
  .inject-num-label {
    font-weight: 600;
    color: #282828;
    padding-top: 10px;
    padding-left: 10px;
  }
  .vaccine-inject-note {
    color: #1691E2;
    font-size: 14px;
    text-align: center;
    padding: 0px 20px 30px;
    font-style: italic;
  }
  .underlying-item {
    display: grid;
    .el-checkbox {
      margin-bottom: 10px;
      align-items: center;
      display: flex;
    }
  }
  .back-button-userinfo {
    background-color: #E9E9E9;
  }
}
.vaccine-covid19-register-success {
  .ep__registration-header {
    padding: 1rem 3rem;
  }
  .inject_info_title {
    color: #233248;
    font-weight: 800;
  }
  .row-vaccine-info {
    display: flex; 
    border-bottom: 1px solid #ddd; 
    align-items: center;
    .eplus-card__icon {
      width: 25px;
    }
  }
  .phone {
    color: #0957DE;
    font-weight: bold;
    text-decoration: none;
  }
  .mail {
    color: #0957DE;
    text-decoration: none;
  }
}
.el-message-box {
  width: auto;
  max-width: 420px;
}
</style>