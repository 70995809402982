<script>
import Base from './Base'

export default {
  extends: Base,

  data () {
    return {
      key: 'Demo'
    }
  }
}
</script>
